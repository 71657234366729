<template>
  <v-card flat>
    <v-card-text class="d-flex justify-center">
      <v-card flat color="white" class="text-center" max-width="600">
        <v-card-title class="justify-center">
          <div class="text-center">
            <img alt="logo" src="@/assets/logo_sm.png"><br />
            Let's get you connected
          </div>
        </v-card-title>
        <v-card-text>
          <v-container class="ma-0 pa-0">
            In order to use Pondr, please associate your google account.
          </v-container>
          <v-btn block color="primary" class="mt-3" large @click="login" rounded>
            Associate Google Account
          </v-btn>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>

</template>

<script>
import authenticator from '@/firebase/authenticator'

export default {
  name: 'Login',
  methods: {
    login () {
      authenticator.logIn()
    }
  }
}
</script>

<style scoped>

</style>
